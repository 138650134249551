import React from 'react'
import Navbar from '../Navbar/Navbar'
import Hero from '../HeroSection/Hero'
import HowItWorks from '../HowItWorks/HowItWorks'
import Features from '../Features/Features'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <main>
      <div className="background">
      {/* <Navbar /> */}
      <Hero />
      </div>
      <HowItWorks />
      <Features />
      <Footer />
    </main>
  )
}

export default Home