import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="footer_section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 footer_col">
            <div className="footer_contact">
              <h4>Reach at..</h4>
              <div className="contact_link_box">
                <a>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  <span> Karol Bagh, New Delhi </span>
                </a>
                <a>
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span> Call +91 9555-555-801 </span>
                </a>
                <a>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span> info@ocpl.tech </span>
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-2 mx-auto footer_col">
            <div className="footer_link_box">
              <h4>Links</h4>
              <div className="footer_links">
                <Link
                  activeclassname="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Home
                </Link>
                <Link
                  activeclassname="active"
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Features
                </Link>
                <Link
                  activeclassname="active"
                  to="works"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  How It Works
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 footer_col">
            <h4>Newsletter</h4>
            <form action="#">
              <input type="email" placeholder="Enter email" />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="footer-info">
          <p>
            &copy; <span id="displayYear"></span> All Rights Reserved By
            <a href="https://ocpl.tech">
              {" "}
              OCPL
              <br />
              <br />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
