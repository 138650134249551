import React, { useState } from "react";
import "./Login.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Swal from "sweetalert2";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Please Enter your Email")
    .matches(/@[^.]*\./, "Must match like: abc@mail.com")
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required"),
});
function Login() {
  //   const Navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const isDisabled = Object.keys(errors).length > 0;
  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://chat.ocpl.tech/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (response.status === 200) {
        reset();
        // Perform any additional actions, e.g., redirecting the user or storing their data in state
        let timerInterval;
        Swal.fire({
          title: `Your Unique ID is generated`,
          html: "Please click OK to redirect to Whatsapp",
          timer: 50000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer || result.isConfirmed) {
            // Perform any action you want when the user clicks the "OK" button
            const phoneNumber = "15155828672";
            const message = encodeURIComponent(
              ` My Unique ID is ${responseData.id}`
            );
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

            window.open(whatsappUrl, "_blank");
          }
        });
      } else {
        let timerInterval;
        Swal.fire({
          title: responseData.error,
          timer: 2000,
          timerProgressBar: true,
      
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
         
        });
      }
    } catch (error) {
      let timerInterval;
        Swal.fire({
          title: error,
          timer: 2000,
          timerProgressBar: true,
      
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
         
        });
    }
  };

  return (
    <div className="background">
      {/* <Navbar /> */}
      <div className="login-container">
        <div className="login__content">
          {/* <img src={LoginBg} alt="login image" className="login__img" /> */}
          <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h1 className="login__title">
                <span>Welcome</span>
              </h1>
              <p className="login__description">Please login to continue.</p>
            </div>
            <div>
              <div className="login__inputs">
                <div>
                  <label htmlFor="email" className="login__label">
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your email address"
                    {...register("email")}
                    className="login__input"
                  />
                </div>
                {errors.email && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "red",
                      fontWeight: "bold",
                      minHeight: "20px",
                    }}
                  >
                    {errors.email.message}
                  </p>
                )}
                <div>
                  <label htmlFor="password" className="login__label">
                    Password
                  </label>
                  <div className="login__box">
                    <input
                      type={isVisible ? "text" : "password"}
                      placeholder="Enter your password"
                      {...register("password")}
                      className="login__input"
                      id="input-pass"
                    />
                    <div
                      onClick={() => {
                        setIsVisible(!isVisible);
                      }}
                    >
                      {isVisible ? (
                        <AiOutlineEye className="login__eye" id="input-icon" />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="login__eye"
                          id="input-icon"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {errors.password && (
                  <p
                    style={{
                      fontSize: 12,
                      color: "red",
                      fontWeight: "bold",
                      minHeight: "20px",
                    }}
                  >
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className="login__check">
                <input type="checkbox" className="login__check-input" />
                <label htmlFor="remember" className="login__check-label">
                  Remember me
                </label>
              </div>
            </div>
            <div>
              <div className="login__buttons">
                <button
                  className="login__button"
                  disabled={isDisabled}
                  
                >
                  Log In
                </button>
              </div>
              <a href="#" className="login__forgot" style={{ color: "red" }}>
                Forgot Password?
              </a>
              <p className="login__description">Already have a account?</p>
              <Link to="/register" className="login__forgot">
                Sign Up Here
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;
