import React from "react";

const HowItWorks = () => {
  return (
  
      <section className="p-10 work-section" id="works">
        <h2 className=" text-center text-2xl  md:text-5xl font-black mt-12">
          How It Works
        </h2>
        <div className="work-subtitle text-base md:text-2xl">
          <p>
            Cresco Divinity Box integrates the GPT-4 architecture into WhatsApp,
            creating a seamless interface between the world's most powerful
            language model and the world's most popular messaging platform.
          </p>
          <br />

          <p>
            Our skilled team of developers and engineers worked tirelessly to
            bring you a product that combines innovation, practicality, and
            elegance. By leveraging the GPT-4 model, Cresco Divinity Box
            understands context, language nuances, and complex concepts,
            delivering a truly divine messaging experience.
          </p>
          <br />
          <p>
            Experience the Future of Messaging: Get ready to explore a new
            dimension of WhatsApp communication with Cresco Divinity Box.
            Immerse yourself in the world of AI-driven interactions and be a
            part of the messaging revolution. Connect, engage, and grow with
            Cresco Divinity Box - where wisdom and technology unite.
          </p>
        </div>
      </section>
 
  );
};

export default HowItWorks;
