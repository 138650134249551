import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";
import { useState } from "react";
import Home from "./Home/Home";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route exact path="/" element={<Home  />} />
        <Route path="/signIn" element={<Login  />} />
        <Route path="/register" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
