import React from "react";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import HeroImage from '../Images/CRESCO DIVINITY.png'
import Image from '../Images/divinegod.png'
const Hero = () => {
  return (
    <Element name="home">
      <section className="hero-section">
        <div className="p-10">
          <img className="logo"></img>
          <h1 className=" text-center text-2xl  md:text-5xl font-black">
            <img src={HeroImage} className="hero-image"></img>
            {/* <span>Cresco Divinity Box</span> */}
             Unleashing Infinite Wisdom
            on WhatsApp
          </h1>
          <img src={Image} className="logo-image" ></img>
          <div className="flex justify-center">
            <Link to="/register">
              <button className=" btn text-white font-bold rounded-full text-center">
                Get Started
              </button>
            </Link>
          </div>
          <p className=" hero-subtitle text-base md:text-2xl">
            Welcome to Cresco Divinity Box - the ultimate AI-driven chat
            experience that transforms your WhatsApp communication with
            unparalleled wisdom and endless possibilities. Discover the power of
            GPT-4 integration and unleash divine intelligence in every
            conversation.
          </p>
          
        </div>
      </section>
    </Element>
  );
};

export default Hero;
