import React from "react";

const Features = () => {
  return (
      <section className="p-2 sm:p-10 features-section" id="features">
        <h2 className=" text-center text-2xl  md:text-5xl font-black mt-12">
          Features
        </h2>
        <div className="text-base md:text-2xl feature-content">
          <p>
            1. Smart Replies: Our advanced AI technology generates contextually
            relevant and intelligently crafted responses in real-time, giving
            you an edge in every conversation.
          </p>

          <br />

          <p>
            2. Multilingual Support: Cresco Divinity Box understands and
            communicates in multiple languages, bridging the gap between diverse
            cultures and fostering seamless global interaction.
          </p>
          <br />
          <p>
            3. Instant Information: Access a wealth of knowledge at your
            fingertips, as Cresco Divinity Box searches for accurate answers to
            your questions in seconds.
          </p>
          <br />
          <p>
            4. Personal Assistant: Manage your daily tasks and reminders with
            ease as Cresco Divinity Box keeps you organized and on track.
          </p>
          <br />
          <p>
            5. Enhanced Security: Your data and privacy are of utmost
            importance. Cresco Divinity Box is built with advanced encryption to
            protect your sensitive information.
          </p>
        </div>
      </section>
  );
};

export default Features;
